import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';
import { ParticipationsTypesCollection } from '../../collections/participations-types.collection';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  /**
   * Api resource.
   *
   * @private
   * @type {string}
   * @memberof StatusService
   */
  private resource: string = 'status';

  /**
   * Status active.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_ACTIVE: number = 1;

  /**
   * Status inactive
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_INACTIVE: number = 2;

  /**
   * Status pending.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_PENDING: number = 3;

  /**
   * Status accepted.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_ACCEPTED: number = 4;

  /**
   * Status rejected.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_REJECTED: number = 5;

  /**
   * Status processing.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_PROCESSING: number = 7;

  /**
   * Status delivered.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_DELIVERED: number = 10;

  /**
   * Status draft
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_DRAFT: number = 11;

  /**
   * Status scheduled.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_SCHEDULED: number = 12;

  /**
   * Status sending.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_SENDING: number = 13;

  /**
   * Status sent.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_SENT: number = 14;

  /**
   * Status cancelled.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_CANCELLED: number = 15;

  /**
   * Status new.
   *
   * @type {number}
   * @memberof StatusService
   */
  public STATUS_NEW: number = 16;

  public STATUS_INCIDENCE = 19;

  public STATUS_DELEGATE = 20;
  public STATUS_REJECTED_ON_READING = 21;
  public STATUS_PAUSE = 22;
  public STATUS_GENERATING = 23;
  public STATUS_GENERATED = 24;
  public STATUS_GENERATE = 25;
  public STATUS_COMPLETED = 26;
  public STATUS_VIVA_INCIDENCE = 27;

  /**
   * Creates an instance of RolesService.
   * @param {AuthorizationService} http
   * @memberof RolesService
   */
  constructor(
    private http: AuthorizationService
  ) { }

  /**
   * List items
   *
   * @returns
   * @memberof RolesService
   */
  findAll(filters: Object = {}, sort: string = 'name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 1000): Observable<any> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  /**
   * Find a single item
   *
   * @param {number} id
   * @returns
   * @memberof RolesService
   */
  findOne(id: number) {
    return this.http.get(`/${this.resource}/` + id);
  }

  /**
   * Get for notifications delivery.
   *
   * @returns {Array<any>}
   * @memberof StatusService
   */
  getForNotificationsDeliveries(): Array<any> {
    return [
      {
        id: this.STATUS_DRAFT,
        name: "STATUS_DRAFT"
      },
      {
        id: this.STATUS_SCHEDULED,
        name: "STATUS_SCHEDULED"
      },
      {
        id: this.STATUS_SENDING,
        name: "STATUS_SENDING"
      },
      {
        id: this.STATUS_SENT,
        name: "STATUS_SENT"
      },
      {
        id: this.STATUS_CANCELLED,
        name: "STATUS_CANCELLED"
      }
    ];
  }

  /**
   * Get status for users
   *
   * @returns
   * @memberof StatusService
   */
  getForUsers(): Array<any> {
    return [
      {
        id: this.STATUS_ACTIVE,
        name: "STATUS_ACTIVE"
      },
      {
        id: this.STATUS_INACTIVE,
        name: "STATUS_INACTIVE"
      },
      {
        id: this.STATUS_PENDING,
        name: "STATUS_PENDING"
      }
    ];
  }


  getValidityStatus(): Array<any> {
    return [
      {
        id: 'empty',
        name: 'Vacío'
      },
      {
        id: 'valid',
        name: 'Válido'
      },
      {
        id: 'invalid',
        name: 'No válido'
      },
      {
        id: 'pending',
        name: 'Pendiente'
      }
    ];
  }


  /**
   * Get active/inactive
   *
   * @returns
   * @memberof StatusService
   */
  getActiveInactive(): Array<any> {
    return [
      {
        id: this.STATUS_ACTIVE,
        name: "STATUS_ACTIVE"
      },
      {
        id: this.STATUS_INACTIVE,
        name: "STATUS_INACTIVE"
      }
    ];
  }

  getParticipationStatus(): Array<any> {
    return [
      {
        id: ParticipationsTypesCollection.ticket,
        name: "Subida de tickets"
      },
      {
        id: ParticipationsTypesCollection.email,
        name: "Envío de pdf"
      }
    ];
  }

  getForAnnouncements(): Array<any> {
    return [
      {
        id: this.STATUS_ACTIVE,
        name: "STATUS_ACTIVE"
      },
      {
        id: this.STATUS_DRAFT,
        name: "STATUS_DRAFT"
      },
      {
        id: this.STATUS_INACTIVE,
        name: "STATUS_INACTIVE"
      }
    ];
  }

  /**
   * Get status for blog.
   *
   * @returns {Array<any>}
   * @memberof StatusService
   */
  getForBlog(): Array<any> {
    return [
      {
        id: this.STATUS_DRAFT,
        name: "STATUS_DRAFT"
      },
      {
        id: this.STATUS_ACTIVE,
        name: "STATUS_ACTIVE"
      }
    ];
  }

  /**
   * Get status for e-learning.
   *
   * @returns {Array<any>}
   * @memberof StatusService
   */
  getForElearning(): Array<any> {
    return [
      {
        id: this.STATUS_DRAFT,
        name: "STATUS_DRAFT"
      },
      {
        id: this.STATUS_ACTIVE,
        name: "STATUS_ACTIVE"
      }
    ];
  }

  /**
   * Get status for sales.
   *
   * @returns
   * @memberof StatusService
   */
  getForSales(): Array<any> {
    return [
      {
        id: this.STATUS_PENDING,
        name: "STATUS_PENDING"
      },
      {
        id: this.STATUS_ACCEPTED,
        name: "STATUS_ACCEPTED"
      },
      {
        id: this.STATUS_REJECTED,
        name: "STATUS_REJECTED"
      },
      {
        id: this.STATUS_INCIDENCE,
        name: "STATUS_INCIDENCE"
      }
    ];
  }

  /**
   * Get status for phases.
   *
   * @returns {Array<any>}
   * @memberof StatusService
   */
  getForPhases(): Array<any> {
    return [
      {
        id: this.STATUS_ACTIVE,
        name: "STATUS_ACTIVE"
      },
      {
        id: this.STATUS_INACTIVE,
        name: "STATUS_INACTIVE"
      },
      {
        id: this.STATUS_DRAFT,
        name: "STATUS_DRAFT"
      }
    ];
  }

  /**
   * Get status for requests.
   *
   * @returns {Array<any>}
   * @memberof StatusService
   */
  getForRequests(): Array<any> {
    return [
      {
        id: this.STATUS_PENDING,
        name: "STATUS_PENDING"
      },
      {
        id: this.STATUS_PROCESSING,
        name: "STATUS_PROCESSING"
      },
      {
        id: this.STATUS_DELIVERED,
        name: "STATUS_DELIVERED"
      },
      {
        id: this.STATUS_REJECTED,
        name: "STATUS_REQUEST_REJECTED"
      },
      {
        id: this.STATUS_DELEGATE,
        name: "STATUS_DELEGATE"
      }
    ];
  }

  /**
   * Get status label by id
   *
   * @param {number} id
   * @returns
   * @memberof StatusService
   */
   getStatusLabel(id: number) {
    switch (id) {
      case this.STATUS_PENDING:
        return 'STATUS_PENDING';
      case this.STATUS_PROCESSING:
        return 'STATUS_PROCESSING';
      case this.STATUS_REJECTED:
        return 'STATUS_REJECTED';
      case this.STATUS_DELIVERED:
        return 'STATUS_DELIVERED';
      case this.STATUS_DELEGATE:
        return 'STATUS_DELEGATE';
      case null:
        return 'NO_STATUS';
      default:
        return '';
    }
  }
}
