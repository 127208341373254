import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorizationService } from '../authorization.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  private resource: string = 'downloads';

  constructor(
    private http: HttpClient,
    private authService: AuthorizationService
  ) { }

  /**
   * Download a file
   *
   * @param {Role} data
   * 
   * @memberof TemplatesService
   */
  downloadFile(data: any, filename: string = null): void {
    const headers = this.getHeadersForDownloads();

    this.http.get(`${environment.apiUrl}/${this.resource}/download-file/${data.url}/${data.notice_to}`, headers).subscribe(
      (response: any) => {
        this.downloadFileFromResponse(response, filename);
      });
  }

  getTemplateForImport(templateName: string) {
    return `${environment.apiUrl}/${this.resource}/import/${templateName}/`;
  }

  getDebugDownload(filename) {
    return `${environment.apiUrl}/${this.resource}/tmp/${filename}/`;
  }

  getTmpFile(filename) {
    return this.getDebugDownload(filename);
  }

  downloadTemplateForImport(templateName: string) {
    const headers = this.getHeadersForDownloads();
    this.http
      .get(
        `${environment.apiUrl}/${this.resource}/import/${templateName}/`,
        headers
      )
      .subscribe((response: any) => {
        this.downloadFileFromResponse(response, templateName);
      });
  }

  downloadTmpFile(filename) {
    const headers = this.getHeadersForDownloads();
    this.http
      .get(
        `${environment.apiUrl}/${this.resource}/tmp/${filename}/`,
        headers
      )
      .subscribe((response: any) => {
        this.downloadFileFromResponse(response, filename);
      });
  }

  /**
   * Download file from url
   * @param filename 
   * @returns 
   */
  downloadFileUrl(filenameUrl) {
    let url = filenameUrl;
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.setAttribute('target', '_blank');
    a.href = url;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  downloadSalesSheetsTemplateForImport(phaseId: number) {
    const headers = this.getHeadersForDownloads();
    this.http
      .get(
        `${environment.apiUrl}/${this.resource}/sales-sheet/${phaseId}/`,
        headers
      )
      .subscribe((response: any) => {
        this.downloadFileFromResponse(response, 'hoja-de-ventas.xlsx');
      });
  }

  downloadFileFromResponse(response: any, filename: string = ''): void {
    let dataType = response.type;
    let binaryData = [];
    binaryData.push(response);
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('style', 'display: none');
    downloadLink.href = window.URL.createObjectURL(
      new Blob(binaryData, { type: dataType })
    );
    if (filename) {
      downloadLink.setAttribute('download', filename);
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
    window.URL.revokeObjectURL(downloadLink.href);
    downloadLink.remove(); // remove the element
  }

  getHeadersForDownloads() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authService.getAccessToken(),
      }),
      responseType: 'blob' as 'json',
    };
  }
}
