// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bee-plugin-container {
  top: 5px;
  bottom: 30px;
  left: 5px;
  right: 5px;
  height: 800px;
}

#integrator-bottom-bar {
  height: 25px;
  bottom: 0px;
  left: 5px;
  right: 0px;
}

.top-bar-right button {
  display: none;
}

.mr-1 {
  margin-right: 1em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRlbXBsYXRlLWVkaXRvci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLFFBQUE7RUFDQSxZQUFBO0VBQ0EsU0FBQTtFQUNBLFVBQUE7RUFDQSxhQUFBO0FBQ0o7O0FBRUE7RUFDSSxZQUFBO0VBQ0EsV0FBQTtFQUNBLFNBQUE7RUFDQSxVQUFBO0FBQ0o7O0FBRUE7RUFDSSxhQUFBO0FBQ0o7O0FBRUE7RUFDSSxpQkFBQTtBQUNKIiwiZmlsZSI6InRlbXBsYXRlLWVkaXRvci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIiNiZWUtcGx1Z2luLWNvbnRhaW5lciB7XG4gICAgdG9wOiA1cHg7XG4gICAgYm90dG9tOiAzMHB4O1xuICAgIGxlZnQ6IDVweDtcbiAgICByaWdodDogNXB4O1xuICAgIGhlaWdodDogODAwcHg7XG59XG5cbiNpbnRlZ3JhdG9yLWJvdHRvbS1iYXIge1xuICAgIGhlaWdodDogMjVweDtcbiAgICBib3R0b206IDBweDtcbiAgICBsZWZ0OiA1cHg7XG4gICAgcmlnaHQ6IDBweDtcbn1cblxuLnRvcC1iYXItcmlnaHQgYnV0dG9uIHtcbiAgICBkaXNwbGF5OiBub25lO1xufVxuXG4ubXItMXtcbiAgICBtYXJnaW4tcmlnaHQ6IDFlbTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/template-editor/template-editor.component.scss"],"names":[],"mappings":"AAAA;EACI,QAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;AACA,o1BAAo1B","sourcesContent":["#bee-plugin-container {\n    top: 5px;\n    bottom: 30px;\n    left: 5px;\n    right: 5px;\n    height: 800px;\n}\n\n#integrator-bottom-bar {\n    height: 25px;\n    bottom: 0px;\n    left: 5px;\n    right: 0px;\n}\n\n.top-bar-right button {\n    display: none;\n}\n\n.mr-1{\n    margin-right: 1em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
