import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service';

@Injectable()
export class AuthGuardService  {

  /**
   *Creates an instance of AuthGuardService.
   * @param {AuthService} auth
   * @param {Router} router
   * @memberof AuthGuardService
   */
  constructor(public auth: AuthService, public router: Router) { }


  /**
   * Indicates if route can be activated
   *
   * @returns {boolean}
   * @memberof AuthGuardService
   */
  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}