import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PhasesService } from 'src/app/core/http/phases/phases.service';

@Component({
  selector: 'app-phase-select',
  templateUrl: './phase-select.component.html',
  styleUrls: ['./phase-select.component.scss']
})
export class PhaseSelectComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  /** list of banks */
  protected communities: Array<any> = [];

  /** control for the selected bank */
  public phaseCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword */
  public phaseFilterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of banks filtered by search keyword */
  public filteredCommunities: ReplaySubject<Array<any>> = new ReplaySubject<Array<any>>(1);

  @ViewChild('singleSelect') singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @Input() phaseId: string;

  @Input() isRequired: boolean = false;

  @Output() valueChanges: EventEmitter<any> = new EventEmitter();


  constructor(
    private phasesService: PhasesService
  ) { }

  selectionChanges(phaseId: string) {
    const data = {
      id: phaseId,
      name: null
    };

    this.communities.forEach((e: any) => {
      if(e._id == phaseId) {
        data.name = e.name;
      }
    });

    this.valueChanges.emit(data);
  }

  /**
   * On view init
   *
   * @memberof PhaseSelectComponent
   */
  ngOnInit(): void {
    if(this.isRequired) {
      this.phaseCtrl.setValidators(Validators.required);
    }
  }

  /**
   * On values changes
   *
   * @param {*} changes
   * @memberof PhaseSelectComponent
   */
  ngOnChanges(changes): void {
    if (changes.phaseId) {
      this.phaseCtrl.setValue(changes.phaseId.currentValue);
      //this.setInitialValue();
    }
  }

  /**
   * On after view init
   *
   * @memberof PhaseSelectComponent
   */
  ngAfterViewInit(): void {
    this.phasesService.findAll({}, 'Phases.name', 'asc', 0, 1000)
      .subscribe((items: any) => {
        this.communities = items.data;
        this.filteredCommunities.next(this.communities.slice());

        // set initial selection
        this.phaseCtrl.setValue(this.phaseId);

        // listen for search field value changes
        this.phaseFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterCommunities();
          });

        //this.setInitialValue();
      });
  }

  /**
   * On view destroy.
   *
   * @memberof PhaseSelectComponent
   */
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  /**
   * Sets the initial value after the communities are loaded initially
   *
   * @protected
   * @memberof PhaseSelectComponent
   */
  protected setInitialValue() {
    this.filteredCommunities
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: any, b: any) => a && b && a._id === b._id;
      });
  }

  /**
   * Filter communities when search
   *
   * @protected
   * @returns {void}
   * @memberof PhaseSelectComponent
   */
  protected filterCommunities(): void {
    if (!this.communities) {
      return;
    }

    // get the search keyword
    let search = this.phaseFilterCtrl.value;
    if (!search) {
      this.filteredCommunities.next(this.communities.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the banks
    this.filteredCommunities.next(
      this.communities.filter(item => item.attributes.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
