import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';


/**
 * Program interface
 *
 * @export
 * @interface Program
 */
export interface Program {
  id: number,
  name: string,
  starts_at: string
  ends_at: string,
  status_id: number
}

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {

  /**
   * Api resource.
   *
   * @private
   * @type {string}
   * @memberof ProgramsService
   */
  private resource: string = 'programs';

  /**
   * Creates an instance of ProgramsService.
   * 
   * @param {AuthorizationService} http
   * @memberof ProgramsService
   */
  constructor(
    private http: AuthorizationService
  ) { }

  findAll(filters: Object = {}, sort: string = 'Programs.is_default', order: string = 'desc', pageNumber: number = 0, pageSize: number = 10) {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=status');
  }

  findOne(id: number) {
    return this.http.get(`/${this.resource}/${id}?include=catalogs_dates`);
  }

  getForSelection() {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString({}, 'Programs.name', 'asc', 0, 5000));
  }

  findLocations(id: number) {
    return this.http.get(`/${this.resource}/${id}/locations?sort=name`);
  }

  findUsers(id: number, params?) {
    return this.http.get(`/${this.resource}/${id}/users?include=locations&sort=name&page[number]=1&page[size]=10000${params ? params : ''}`);
  }

  findActiveProgram() {
    return this.http.get(`/${this.resource}/active?include=phases&fields[programs]=id`);
  }

  findActiveProgramAndActivePhases() {
    return this.http.get(`/${this.resource}/active?include=active_phases`);
  }

  findActiveProgramAndAllActivePhases() {
    return this.http.get(`/${this.resource}/active?include=all_active_phases`);
  }

  getActiveProgram() {
    return this.http.get(`/${this.resource}/active`);
  }

  getActiveProgramInstructions() {
    return this.http.get(`/${this.resource}/active?fields[programs]=instructions`);
  }

  store(data: Program) {
    if (data.id) {
      return this.http.put(`/${this.resource}/${data.id}`, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  delete(ids) {
    return this.http.delete(`/${this.resource}/${ids.toString()}`)
  }
}
