// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.month-picker .mat-calendar-period-button {
  pointer-events: none;
}

.month-picker .mat-calendar-arrow {
  display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vbnRseS1kYXRlcGlja2VyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksb0JBQUE7QUFDSjs7QUFFRTtFQUNFLGFBQUE7QUFDSiIsImZpbGUiOiJtb250bHktZGF0ZXBpY2tlci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5tb250aC1waWNrZXIgLm1hdC1jYWxlbmRhci1wZXJpb2QtYnV0dG9uIHtcbiAgICBwb2ludGVyLWV2ZW50czogbm9uZTtcbiAgfVxuICBcbiAgLm1vbnRoLXBpY2tlciAubWF0LWNhbGVuZGFyLWFycm93IHtcbiAgICBkaXNwbGF5OiBub25lO1xuICB9Il19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/montly-datepicker/montly-datepicker.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;;AAEE;EACE,aAAA;AACJ;AACA,gfAAgf","sourcesContent":[".month-picker .mat-calendar-period-button {\n    pointer-events: none;\n  }\n  \n  .month-picker .mat-calendar-arrow {\n    display: none;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
