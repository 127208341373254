import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UniqueEmailDirective } from './directives/unique-email.directive';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { PaginatorDialogComponent } from './components/paginator-dialog/paginator-dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PreviewImageComponent } from './components/preview-image/preview-image.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CommonModule, DatePipe } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkTableModule } from '@angular/cdk/table';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { SegmentationUsersComponent } from './components/segmentation-users/segmentation-users.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ProgramSelectionComponent } from './components/program-selection/program-selection.component';
import { PhaseSelectComponent } from './components/phase-select/phase-select.component';
import { ProductsPointsComponent } from './components/products-points/products-points.component';
import { ProductsAmountComponent } from './components/products-amount/products-amount.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { ChangesRegistryComponent } from './components/changes-registry/changes-registry.component';
import { BtnDownloadUrlComponent } from './components/btn-download-url/btn-download-url.component';
import { AutocompleteSelectorComponent } from './components/autocomplete-selector/autocomplete-selector.component';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { MaterialModule } from '../material/material.module';
import { TemplateEditorDialogComponent } from './components/template-editor-dialog/template-editor-dialog.component';
import { StatusDialogComponent } from './components/status-dialog/status-dialog.component';
import { DatepickerMonthSelectionComponent } from './components/montly-datepicker/montly-datepicker.component';


@NgModule({
  declarations: [
    DatePickerComponent,
    ConfirmDialogComponent,
    PreviewImageComponent,
    LoadingComponent,
    InfoDialogComponent,
    PaginatorDialogComponent,
    UniqueEmailDirective,
    SafePipe,
    SegmentationUsersComponent,
    ProgramSelectionComponent,
    PhaseSelectComponent,
    ProductsPointsComponent,
    ProductsAmountComponent,
    StarRatingComponent,
    ChangesRegistryComponent,
    BtnDownloadUrlComponent,
    AutocompleteSelectorComponent,
    TemplateEditorComponent,
    TemplateEditorDialogComponent,
    StatusDialogComponent,
    DatepickerMonthSelectionComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    FormsModule,    
    NgxMatSelectSearchModule,
  ],
  exports: [
    ClipboardModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    CdkTableModule,
    CdkTreeModule,
    ReactiveFormsModule,
    UniqueEmailDirective,
    FileUploadModule,
    SafePipe,
    NgxChartsModule,
    SegmentationUsersComponent,
    PhaseSelectComponent,
    ProductsPointsComponent,
    ProductsAmountComponent,
    StarRatingComponent,
    ChangesRegistryComponent,
    BtnDownloadUrlComponent,
    AutocompleteSelectorComponent,
    TemplateEditorComponent,
    TemplateEditorDialogComponent,
    StatusDialogComponent,
    DatepickerMonthSelectionComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        }
      }
    },
    DatePipe
  ]
})

export class SharedModule { }
