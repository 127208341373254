import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';
import { LocationsTypesListResource, LocationTypeResource } from 'src/app/core/interfaces/locations-types.resource';

@Injectable({
  providedIn: 'root'
})
export class LocationsTypesService {

  /**
   * Api resource.
   *
   * @private
   * @type {string}
   * @memberof LocationsTypesService
   */
  private resource: string = 'locations-types';

  /**
   * Type gold.
   *
   * @type {number}
   * @memberof LocationsTypesService
   */
  public TYPE_GOLD: number = 1;

  /**
   * Type silver.
   *
   * @type {number}
   * @memberof LocationsTypesService
   */
  public TYPE_SILVER: number = 2;

  /**
   * Type bronze.
   *
   * @type {number}
   * @memberof LocationsTypesService
   */
  public TYPE_BRONZE: number = 3;

  /**
   * Creates an instance of LocationsClassesService.
   * 
   * @param {AuthorizationService} http
   * @memberof LocationsTypesService
   */
  constructor(
    private http: AuthorizationService
  ) { }

  /**
   * Find all items.
   *
   * @param {*} [filters={}]
   * @returns {Observable<any>}
   * @memberof LocationsTypesService
   */
  findAll(filters = {}, sort: string = 'LocationsTypes.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<LocationsTypesListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  /**
   * Find a single item.
   *
   * @param {number} id
   * @returns {Observable<any>}
   * @memberof LocationsTypesService
   */
  findOne(id: number, params?: string): Observable<LocationTypeResource> {
    return this.http.get(`/${this.resource}/${id}${this.http.parseParams(params)}`);
  }

  /**
   * Stores an item
   *
   * @param {*} data
   * @returns {Observable<UsersTypesResource>}
   * @memberof LocationsTypesService
   */
  store(data): Observable<LocationTypeResource> {
    if (data.id) {
      return this.http.put(`/${this.resource}/` + data.id, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Delete an item/s.
   *
   * @param {*} ids
   * @returns {Observable<any>}
   * @memberof LocationsTypesService
   */
  delete(ids): Observable<any> {
    return this.http.delete(`/${this.resource}/${ids.toString()}`);
  }

  /**
   * Get location type label by id
   *
   * @param {number} id
   * @returns
   * @memberof StatusService
   */
  getStatusLabel(id: number) {
    switch (id) {
      case this.TYPE_GOLD:
        return 'TYPE_GOLD';
      case this.TYPE_SILVER:
        return 'TYPE_SILVER';
      case this.TYPE_BRONZE:
        return 'TYPE_BRONZE';
      default:
        return '';
    }
  }
}
